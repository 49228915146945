<template>
  <van-field
    v-model="password"
    maxlength="16"
    clearable
    @input="onChange"
    :type="inputType"
    :right-icon="inputType==='password'?'closed-eye':'eye-o'"
    @click-right-icon="changeType()"
    placeholder="请输入6~16位数字或字母密码" />
</template>

<script>
export default {
  name: 'pwdInput',
  data() {
    return {
      password: '',
      inputType: 'password'
    }
  },
  methods: {
    onChange(e) {
      this.$parent.form.password = e
    },
    changeType() {
      this.inputType = this.inputType === 'password' ? 'text' : 'password'
    }
  }
}
</script>