<template>
  <div class="container">
    <nav-bar />
    <div class="header">
      <div class="login-type">
        <div class="type-title">注册账号</div>
      </div>
      <div class="login-form">
        <phone-input :needCode="true" />
        <code-input :disCode.sync="disCode" :action="1" />
        <pwd-input />
        <Submit ref="submit" :disBtn="$checkBtn(form)" @submit="toReg()" btnStr="完成" />
      </div>
      <Clause />
    </div>
    <Footer pageType="reg" />
  </div>
</template>

<script>
import NavBar from "@/components/navbar";
import PhoneInput from "@/components/phoneInput";
import CodeInput from "@/components/codeInput";
import PwdInput from "@/components/pwdInput";
import Submit from "@/components/submit";
import Footer from "@/components/footer";
import Clause from '@/components/clause';
import { register } from "@/api/login";
import { trackClickRegister, startTrackApp } from "@/tongji";
export default {
  name: "reg",
  components: {
    NavBar,
    PhoneInput,
    CodeInput,
    PwdInput,
    Submit,
    Footer,
    Clause,
  },
  data() {
    return {
      form: {
        mobile: "",
        code: "",
        password: "",
      },
      disCode: true,
    };
  },
  methods: {
    toReg() {
      let _this = this;
      trackClickRegister({}, function () {
        let { mobile, code, password } = _this.form;
        let obj = {
          mobile_info: {
            mobile,
            code,
          },
          password,
        };
        register(obj)
          .then((res) => {
            _this.$loginCb(res.data.access_token);
          })
          .catch((res) => {
            if (res.code === 61003) {
              _this.$dialog
                .confirm({
                  message: "手机号已注册，现在就登录？",
                  confirmButtonColor: "#494FF5",
                  confirmButtonText: "是",
                  cancelButtonText: "否",
                })
                .then(() => {
                  _this.$router.push("/");
                })
                .catch(() => {});
            }
            _this.$refs.submit.loading = false;
          });
      });
    },
  },
  mounted() {
    startTrackApp("注册账号");
  },
};
</script>

<style lang="less">
//提示框
.van-dialog {
  width: 5.6rem;
}
.van-dialog__message {
  font-size: 0.36rem;
  color: #363f4a;
  margin-top: 0.2rem;
}
</style>
